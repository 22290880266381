import {PropsWithChildren} from 'react';
import {motion} from 'framer-motion';
import styled from 'styled-components';

import {AppLogo} from 'app/components';
import HashIMG from './hash.svg';
import { useMediaQuery } from 'usehooks-ts'
import Intercom from '@intercom/messenger-js-sdk';
import { useAppSelector } from 'store';
import { useEffect } from 'react';

const StyledAppLogo = styled(AppLogo)`
    margin: 80px auto 24px;

    svg {
        transform: scale(1.15);

        path {
            fill: var(--text-alt);
        }
    }
    

    &.blue {
        display: flex;
        justify-content: center;
        margin: 0;
        padding-top: 24px;
        padding-bottom: 24px;
        transition: none;
        animation: none !important;
        animation-name: none !important;
        animation-duration: 0s !important;
        transform-origin: center;
        svg {
            path {
                fill: var(--blue);
            }
        }
    }
`;

const Caption = styled.span`
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -1.25px;
    color: var(--text-alt);
`;

const Banner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: calc(50% + 2px);
    padding: 0 64px;
    z-index: 1;
`;

const HashImage = styled.div`
    position: absolute;
    overflow: hidden;

    img {
        width: 245px;
        height: 245px;
        opacity: 0.4;
    }

    &:first-child {
        top: 24.8%;
        left: -9.72%;
    }

    &:last-child {
        bottom: -2.25%;
        left: -6.81%;

        img {
            transform: translateY(30px);
        }
    }

    &:nth-child(2) {
        right: -6.2%;
        top: 57.62%;
    }
`;

const UserImageBadge = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: var(--background-alt);

    img {
        max-width: 45px;
        max-height: 45px;
    }
`;

const UserImage = styled(motion.div)`
    position: absolute;
    top: -100%;

    img {
        border-radius: 50%;
    }
    
    &:first-child {
        top: 77%;
        left: 5%;

        img {
            width: 146px;
            height: 146px;
        }
    }

    &:last-child {
        top: 30%;
        right: 6%;

        img {
            width: 120px;
            height: 120px;
        }
    }

    &:nth-child(2) {
        top: 32%;
        left: 8%;

        img {
            width: 160px;
            height: 160px;
        }
    }

    &:nth-child(3) {
        top: 54%;
        left: 22%;

        img {
            width: 159px;
            height: 159px;
        }
    }

    &:nth-child(4) {
        top: 42%;
        left: 50%;

        img {
            width: 100px;
            height: 100px;
        }
    }

    &:nth-child(5) {
        top: 73%;
        left: 45%;

        img {
            width: 182px;
            height: 182px;
        }
    }

    &:nth-child(6) {
        top: 52%;
        left: -15px;

        img {
            width: 87px;
            height: 87px;
        }
    }

    &:nth-child(7) {
        top: 55%;
        right: 18%;

        img {
            width: 132px;
            height: 132px;
        }
    }
`;

const UserImageList = styled(motion.div)``;

const Side = styled.div`
    flex-shrink: 0;
    flex-direction: column;
    position: relative;
    width: calc(50% + 2px);
    height: auto;
    min-height: 100vh;
    text-align: center;
    background-color: var(--blue);

    @media(min-width: 300px) {
        display: none;
    }

    @media(min-width: 768px) {
        display: flex;
    }
`;

const Main = styled.main`
    flex: 1 0;
    position: relative;
    background-color: var(--background-alt);
    overflow: hidden auto;
    overflow: hidden overlay;
    padding: 72px 120px;

    @media (max-width:900px) and (min-width:0px) {
        padding: 20px 24px;
        overflow: static;
    }
`;

const Container = styled.div`
    display: flex;
    overflow: hidden;
`;

const badges = [
    require('../../../images/bell.png'),
    require('../../../images/gift.png'),
    require('../../../images/lightning.png'),
];

const images = [
    require('../../../images/landing/image-1.png'),
    require('../../../images/landing/image-2.png'),
    require('../../../images/landing/image-8.png'),
    require('../../../images/landing/image-3.png'),
    require('../../../images/landing/image-4.png'),
    require('../../../images/landing/image-5.png'),
    require('../../../images/landing/image-6.png'),
    require('../../../images/landing/image-7.png'),
];

export function PublicLayout({
    children
}: PropsWithChildren<any>) {

    const {
        profile: {
            brand,
            representative,
            loading
        },
    } = useAppSelector((state) => state);

    useEffect(() => {
        if (representative?.uid) {
            Intercom({
                app_id: 'wvexrbql',
                user_id: representative?.uid,
                name: brand?.name,
                email: representative?.auth?.email,
            });
        }
    }, [brand, representative]);

    const userImageListAnimation = {
        initial: 'hidden',
        animate: 'show',
        variants: {
            show: {
                transition: {
                    staggerChildren: 0.075
                }
            }
        }
    };

    const userImagesAnimation = {
        transition: {
            type: 'spring',
            duration: 0.8
        },
        variants: {
            hidden: { opacity: 0, scale: 0, y: 300 },
            show: { opacity: 1, scale: 1, y: 0 }
        }
    };

    const smallDevice = useMediaQuery('(max-width: 900px)')

    return (
        <Container data-testid={'public-layout'}>
            <Side>
                <Banner>
                    <StyledAppLogo initial={false} />

                    <Caption>
                        The new way to create unique photos, videos, reviews, testimonials, and social buzz
                    </Caption>
                </Banner>

                <div>
                    {Array(3).fill('').map((_, i) => (
                        <HashImage key={i}>
                            <img src={HashIMG} alt={'hash'} />
                        </HashImage>
                    ))}
                </div>

                <UserImageList {...userImageListAnimation}>
                    {images.map((image, i) => (
                        <UserImage
                            {...userImagesAnimation}
                            key={i}
                        >
                            <img
                                src={image}
                                alt={`user profile ${i}`}
                            />

                            {badges[i] && (
                                <UserImageBadge
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 2 + (i * 0.5) }}
                                >
                                    <motion.img
                                        animate={{
                                            scale: [1, 1.2, 1.2, 1.2, 1.2, 1],
                                            rotate: [0, -5, 5, -3, 3, 0]
                                        }}
                                        transition={{
                                            delay: 5,
                                            duration: 0.5 + Math.random(),
                                            times: [0, 0.2, 0.5, 0.8, 1],
                                            repeat: Infinity,
                                            repeatDelay: 1 + Math.random()
                                        }}
                                        src={badges[i]}
                                        alt={''}
                                    />
                                </UserImageBadge>
                            )}
                        </UserImage>
                    ))}
                </UserImageList>
            </Side>

            <Main data-testid={'public-layout-main'}>
                {smallDevice ? <StyledAppLogo className='blue' initial={false} /> : null}
                {children}
            </Main>
        </Container>
    );
}
