import {motion} from 'framer-motion';
import styled from 'styled-components';

import {Helpers} from 'utils';
import {WaveListItem} from '../WaveListItem';
import {Loader} from 'app/components/Loader';
import MatchOverlay from "./matchOverlay";
import {useAppSelector} from "../../../../store";
import {useCallback, useEffect, useMemo, useRef} from "react";
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from 'services/settingsService';

interface Props {
    items: Wave[];
    selectedTabId: string;
    selectedId?: string;
    loading?: boolean;
    loadingViewMore?: boolean;
    onClick(wave: Wave): void;
    onStatusUpdate(
        waveId: string,
        status: string,
        influencer: Partial<Influencer>
    ): void;
    hasMore?: boolean;
    onViewMore(): void;
    badgeCounts: BadgeCounts;
    fetchMoreWaves(): void;
    currentPage: number;
    lastPage: number;
    searching: boolean;
}

export function WaveList({
    items,
    selectedTabId,
    selectedId,
    loading,
    loadingViewMore,
    onClick,
    onStatusUpdate,
    badgeCounts,
    hasMore,
    fetchMoreWaves,
    searching,
}: Props) {
    const {
        gift,
        profile: {brand },
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const observerTarget = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
          entries => {
            if (entries[0].isIntersecting) {
              fetchMoreWaves();
            }
          },
          { threshold: 1 }
        );

        if (observerTarget.current) {
          observer.observe(observerTarget.current);
        }

        return () => {
          if (observerTarget.current) {
            observer.unobserve(observerTarget.current);
          }
        };
      }, [observerTarget, loading, loadingViewMore]);

    const list = useMemo(() => loading ? placeholder : items, [loading, items]) as Wave[];

    const analyticsEvents = new AnalyticsEvents(settings as ClientSettings);

    useEffect(() => {
        if(list.length === 1 && list[0].status == "SUBMITTED") {
            analyticsEvents.giftFirstApplicant({
                analytic: list[0].analytic && list[0].analytic
            }
            );
        }
    }, [list]);

    const onFirstCreatorCheck = useCallback((status: string) => {
        if (list.length === 1 && status === 'accept') {
            analyticsEvents.giftFirstAcceptedApplicant({
                analytic: list[0].analytic && list[0].analytic
            });
        }
    }, [list, analyticsEvents]);

    const removeDuplicatesFromList = (list: Wave[]) => {
        return list.filter((item, index, self) =>
            index === self.findIndex((t) => t.influencer?.uid === item.influencer?.uid)
        );
    }

    return (
        <Container
            className={`wave-list ${selectedTabId}`}
            $loading={loading}
        >
            {
                removeDuplicatesFromList(list).map((item, i) => (
                    (item?.influencer?.uid) && (
                        <>
                            <WaveListItem
                                {...item}
                                wave={item as Wave}
                                key={item?.uid}
                                customClass={'wave-list'}
                                index={i}
                                gift={gift}
                                brand={brand as BrandProfile}
                                id={item.influencer?.uid}
                                name={item.influencer.name}
                                headshotImage={item.influencer.headshot_image}
                                matchPercentage={item.influencer_match_per}
                                badgeCounts={badgeCounts}
                                waveStatus={item.status}
                                selected={item?.uid === selectedId}
                                withdrawn={!!item.influencer_withdrawn_at}
                                loading={loading}
                                onClick={() => onClick(item as Wave)}
                                onStatusUpdate={(status) => {
                                    onFirstCreatorCheck(status)
                                    onStatusUpdate(item?.uid, status, item.influencer)
                                }}
                            />
                        </>
                    )
                ))
            }


            {hasMore && !loading && !loadingViewMore && (
                <div style={{ backgroundColor: 'cyan' }} ref={observerTarget}></div>
            )}

            {brand && gift.item && !hasMore && !loadingViewMore && (
                <MatchOverlay gift={gift.item as GiftItem} brand={brand} items={items} selectedTabId={selectedTabId} selectedId={selectedId} loading={loading} searching={searching}/>
            )}

            {loadingViewMore && (
                <CenterContainer>
                    <Loader status="spin" />
                </CenterContainer>
            )}
        </Container >
    );
}

const placeholder = Array(7).fill('').map(() => ({
    uid: Helpers.generateId(),
    influencer: {
        uid: Helpers.generateId(),
        name: '',
        headshot_image: '',
        bio: '',
    },
    latest_bubble: {
        created_at: ''
    },
    influencer_match_per: 100,
    status: '',
    status_updated_at: '',
    influencer_withdrawn_at: ''
}));

const Container = styled(motion.div) <{
    $loading?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 10px 20px 20px;
    margin-top: -10px;
    position: relative;

    &.no-scroll {
        // overflow: hidden !important;
    }

    ${(props) => props.$loading && `
        pointer-events: none;
    `}

    @media (max-width:900px) and (min-width:0px) {
        // overflow-y: scroll;
    }
`;

const CenterContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 56px;
    align-items: center;
    padding-top: 16px;
`
