import {PropsWithChildren} from 'react';
import styled from 'styled-components';

import { PrivateLayoutHeader } from './Header';
import Intercom from '@intercom/messenger-js-sdk';
import { useAppSelector } from 'store';
import { useEffect } from 'react';
const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Main = styled.main`
    height: calc(100vh - 56px);
    padding-bottom: 60px;
    overflow: hidden auto;
    overflow: hidden overlay;

    @media (max-width:900px) and (min-width:0px) {
        height: auto;
        padding-top: 56px;
        overflow: unset;
    }

`;

export function PrivateLayout({
    children
}: PropsWithChildren<any>) {

    const {
        profile: {
            brand,
            representative,
            loading
        },
    } = useAppSelector((state) => state);

    useEffect(() => {
        if (representative?.uid) {

            Intercom({
                app_id: 'wvexrbql',
                user_id: representative?.uid,
                name: brand?.name,
                email: representative?.auth?.email,
            });
        }
    }, [brand, representative]);
    
    
    return (
            <Container data-testid={'private-layout'}>
                <PrivateLayoutHeader />

                <Main data-testid={'private-layout-main'}>
                    {children}
                </Main>
            </Container>
    );
}
