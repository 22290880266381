import React, {PropsWithChildren, useEffect, useMemo, useState, useRef } from 'react';
import styled from 'styled-components';

import {Actions, useAppDispatch, useAppSelector} from 'store';
import {
    Button,
    EmptyPlaceholder,
    FormInput,
    GiftModal,
    InfluencerCard, ModalBtnLayout, NoResult,
    ReviewModal,
    Tab,
    TabItemProps
} from 'app/components';
import {WaveList} from '../WaveList';
import {InfluencerInfo} from '../InfluencerInfo';
import {InfluencerChat} from '../InfluencerChat';
import {giftAPI} from 'api/gift';
import {WaveStatus} from "../../../../api/wave";
import {TabItem} from "../../../components/Tab";
import { FilterButtons } from 'app/components/FilterButtons';
import {FilterSelect, Option} from 'app/components/FilterSelect';
import EmptyState from "./emptyState";
import {API} from "../../../../api";
import errorReporter from "../../../../services/errorReporter";
import {useMediaQuery} from 'usehooks-ts'
import {AngleLeftIcon, SearchIcon} from 'app/icons';
import AnalyticsEvents from "services/analyticsEvents";
import {ClientSettings} from "../../../../services/settingsService";
import {trim} from "lodash";
import {AcceptIcon, CheckIcon, CloseIcon, PlusIcon} from 'app/icons';
import toast, { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga4';

interface Props {
    giftId: string;
    paramsStatus: string;
    waveByUrl?: WaveItem;
    linkToStatus?: string;
}

interface ModalStateType {
    show: boolean;
    influencer?: Influencer | undefined;
    completed?: boolean;
}

export interface TabDefinition extends TabItem {
    statuses: Array<WaveStatus>;
}

const tabItems: Array<TabDefinition> = [
    { id: 'new', name: 'Applicants', badgeId: 'SUBMITTED', statuses: [WaveStatus.SUBMITTED, WaveStatus.NEGOTIATION] },
    { id: 'shortlist', name: 'Shortlist', badgeId: 'SHORTLISTED', statuses: [WaveStatus.SHORTLISTED] },
    { id: 'accepted', name: 'Selected', badgeId: 'ACCEPTED', statuses: [WaveStatus.ACCEPTED, WaveStatus.COMPLETED] },
    { id: 'rejected', name: 'Declined', badgeId: 'REJECTED', statuses: [WaveStatus.REJECTED] }
];


export function WavesTabs({
    giftId,
    paramsStatus,
    waveByUrl,
    children,
    linkToStatus
}: PropsWithChildren<Props>) {

    ///
    /// State
    ///

    const dispatcher = useAppDispatch();

    const {
        gift,
        profile: { brand, representative },
        wave: {
            list,
            view,
            paginations,
            loading,
            loadingView,
            loadingReviews,
            loadingViewMore,
            showPIIModal,
            lastReceivedBubble
        },
        global: {
            settings
        },
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(() => new AnalyticsEvents(settings as ClientSettings), [settings]);

    const listPagination = paginations.list;

    const [selectedTab, setSelectedTab] = useState<TabDefinition>(linkToStatus ? tabItems.find(item => item.id === linkToStatus) as TabDefinition : tabItems[0]);

    const [selectedWave, setSelectedWave] = useState<Wave | undefined>(undefined);

    const [viewProfile, setViewProfile] = useState<boolean>(false);

    const [viewReviews, setViewReviews] = useState<boolean>(false);

    const [analytics, setAnalytics] = useState<Analytics | undefined>();

    const [modalState, setModalState] = useState<ModalStateType>({ show: false, influencer: undefined, completed: false });

    const [badgeCounts, setBadgeCounts] = useState<BadgeCounts>({})

    const [unreadByTab, setUnreadByTab] = useState<Dynamic>({});

    const [settingInitial, setSettingInitial] = useState(true);

    const isFirstLoad = useMemo(() => !!selectedTab, [selectedTab])

    const smallDevice = useMediaQuery('(max-width: 900px)')

    const [dismissModals, setDismissModals] = useState(false);

    const nextWaveInList = useMemo(() => {
        const index = list.findIndex(value => value.uid === selectedWave?.uid) + 1;
        if (index == -1) {
            return list.length > 0 ? list[0] : null;
        }
        return list[index];
    }, [selectedWave, list]);

    const is_rank_enabled = useMemo(() => settings?.features['influencer.rank.release.enabled'], [settings]);

    const options: Array<Option> = useMemo(() => is_rank_enabled ? [
        {id: 'rank', name: 'best match'},
        {id: 'newest', name: 'newest'},
        {id: 'oldest', name: 'oldest'},
        {id: 'follower', name: 'followers'},
        {id: 'engagement', name: 'engagement'}
    ] : [
        {id: 'newest', name: 'newest'},
        {id: 'oldest', name: 'oldest'},
        {id: 'follower', name: 'followers'},
        {id: 'engagement', name: 'engagement'}
    ], [is_rank_enabled]);

    const [selectedSort, setSelectedSort] = useState<Option>(options[0]);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1)
    const scrollContainer = useRef(null);

    const [search, setSearch] = useState<string>('');

    const isSearching = useMemo(() => !!trim(search), [search]);

    /**
     * Shows the wave list if the tab is active or past, but not for submitted or draft
     */
    const showWaveList = useMemo(() => {
        if (!gift.item) {
            return false;
        }
        return !['SUBMITTED', 'DRAFT', 'DECLINED', 'INCOMPLETE'].includes(gift.item?.status);
    }, [paramsStatus, gift.item]);

    ///
    /// Effects
    ///
    /**
     * Handles updating the UI when new bubbles are received
     */
    useEffect(() => {
        if (!giftId) {
            return;
        }

        const waveId = lastReceivedBubble?.wave?.uid;
        if (!waveId) {
            return;
        }

        const bubbleGiftId = lastReceivedBubble?.wave?.gift?.uid;
        // Don't refresh if bubble not related to the gift
        if (!bubbleGiftId || giftId !== bubbleGiftId) {
            return;
        }

        // Reload counts when bubble comes in and update tabs
        const markAsSeen = view?.uid == waveId;

        API.Wave.getItem(giftId, waveId, markAsSeen).then((wave) => {
            updateTabUnreadCounts();
            dispatcher(Actions.Wave.updateWaveInList(wave));
        }).catch((reason) => {
            errorReporter.report('could not reload wave', reason);
        });

    }, [lastReceivedBubble]);

    /**
     * Opens wave on right hand side when selected
     */
    useEffect(() => {
        selectedWave && openWave(selectedWave);
    }, [selectedWave]);

    /**
     * Set the selected tab when deep linking to a wave
     */
    useEffect(() => {
        if (waveByUrl) {
            const tabDefinition = tabItems.find((tab) => tab.statuses.find(value => value === waveByUrl.status));
            if (tabDefinition) {
                setSelectedTab(tabDefinition);
                openWave(waveByUrl);
            }
        }
    }, [waveByUrl])

    /**
     *
     * Set the selected tab when deep linking to a tab
    */
    useEffect(() => {
        if (linkToStatus) {
            const tabDefinition = tabItems.find(item => item.badgeId === linkToStatus.toUpperCase())
            if (tabDefinition) {
                setSelectedTab(tabDefinition);
            }
        }
    }, [linkToStatus])

    /**
     * Fetches the waves when the sort is changed
     */
    useEffect(() => {
        if (showWaveList && selectedSort) {
            dispatcher(Actions.Wave.getByStatuses(
                giftId,
                selectedTab.statuses,
                { page: 1 },
                selectedSort.id as string,
                selectedFilter,
                search,
            )).catch((error: any) => {throw error});
        }

        return () => {
            dispatcher(Actions.Wave.clearState());
        }
    }, [giftId, dispatcher, selectedTab, showWaveList, brand?.subscription]);

    useEffect(() => {
      analyticsEvents.giftManageMatchesOpened(selectedTab.name);
      updateTabUnreadCounts();
    }, [selectedTab]);

    /**
     * Handles when the selected sort is changed
     */
    useEffect(() => {
        if (selectedSort && options && selectedSort && !settingInitial) {
            dispatcher(Actions.Wave.getByStatuses(
                giftId,
                selectedTab.statuses,
                { page: 1 },
                selectedSort.id as string,
                selectedFilter,
                search,
                true,
            )).catch((error: any) => {throw error});
        }
    }, [selectedSort, selectedFilter, options, search]);

    /**
     * Set the selected wave when we receive a deep link
     */
    useEffect(() => {
        if (!selectedWave && !waveByUrl && list.length > 0 && selectedSort && !smallDevice) {
            const wave = list[0];
            if (wave && wave.influencer) {
                openWave(wave);
            }
        }
    }, [waveByUrl]);

    /**
     * Clears the state when the tab is changed
     */
    useEffect(() => {
        if (!selectedTab) {
            return () => {
                dispatcher(Actions.Gift.clearState());
            }
        }

    }, [dispatcher, selectedTab, selectedWave]);

    /**
     * Updates the badge counts when the list, selected tab or wave, or when last bubble is changed
     */
    useEffect(() => {
        updateListBadgeCounts();
    }, [list, selectedTab, selectedWave, giftId, view, lastReceivedBubble]);

    /**
     *
     */
    useEffect(() => {
        options && options.length > 0 && !selectedSort && setSelectedSort(options[0])
    }, [options]);

    /**
     * sets the initial state flag when the options, filter or sort is changed
     */
    useEffect(() => {
        if(options && selectedFilter && selectedSort) {
            setSettingInitial(false);
        }
    }, [options, selectedFilter, selectedSort])

    /**
     * Resets the scroll position when the tab changes
     */
    useEffect(() => {
        setCurrentPage(1)
        //@ts-ignore
        scrollContainer.current?.scrollTo(0,0)
    }, [selectedTab]);

    ///
    /// Methods
    ///

    const fetchMoreWaves = () => {
        if (listPagination && currentPage > listPagination?.last_page) {
            getMoreWaves(listPagination.last_page)
            setCurrentPage(listPagination.last_page)
        } else {
            const newPage = currentPage + 1;
            getMoreWaves(newPage)
            setCurrentPage(newPage)
        }
    }

    const unselectWaveItem = () => {
        setSelectedWave(undefined);
    }

    /**
     * Updates the tab badge counts
     */
    const updateTabUnreadCounts = () => {
        giftAPI.getUnreadWaveCounts(giftId).then((res) => {
            setUnreadByTab(res)
        }).catch((error: any) => {throw error});
    }

    /**
     * Updates the wave list badge counts
     */
    const updateListBadgeCounts = () => {
        const badges: BadgeCounts = {};
        list.forEach((wave) => {
            if (selectedWave?.uid === wave.uid) {
                // Make sure to mark the selected wave as viewed
                badges[wave.uid] = 0;
            } else {
                badges[wave.uid] = wave.unread_bubbles_count;
            }
        })
        setBadgeCounts(badges);
    }

    /**
     * Loads more waves on scroll
     * @param page
     */
    const getMoreWaves = (page: number) => {
        selectedSort && selectedFilter && dispatcher(Actions.Wave.getByStatuses(
            giftId,
            selectedTab.statuses,
            { page },
            selectedSort.id as string,
            selectedFilter,
            search
        )).catch((error: any) => {throw error});
    }

    /**
     * Handle the tab change
     * @param item
     */
    const handleTabChange = (item: TabItemProps) => {
        setSelectedWave(undefined);
        const definition = tabItems.filter(candidate => candidate.id === item.id)[0];
        setSelectedTab(definition);
    }

    /**
     * Opens the wave and shows it on the right
     * @param wave
     * @param reload
     */
    const openWave = (wave: Wave, reload = true) => {
        setSelectedWave(wave);
        setViewProfile(false);
        setAnalytics(undefined);
        dispatcher(Actions.Wave.getWaveItem(giftId, wave.uid, true, reload, (wave) => {
            console.log('wave', wave)
            // pre-select the first insight
            // if there are any
            analyticsEvents.waveConversationOpened(selectedTab.name, wave);
            const insights = Object.entries(wave.influencer.insights || {});
            if (insights.length > 0) {
                const [key, value] = insights[0];

                setAnalytics({ ...value, platform: key as SocialPlatformType });
            }

            dispatcher(Actions.Wave.updateWaveInList(wave));

            updateTabUnreadCounts();

        })).catch((error: any) => {throw error});
    }

    /**
     * Handler for the select/shortlist/decline button actions
     * @param waveId
     * @param status
     * @param influencer
     */
    const waveStatusUpdated = (waveId: string, status: string, influencer: Influencer) => {
        dispatcher(Actions.Wave.setStatus(giftId, waveId, status, () => {
            if (gift.item && brand) {
                if (status === 'accept') {
                    analyticsEvents.giftInfluencerAccepted(gift.item, brand, influencer);
                }

                if (status === 'reject') {
                    analyticsEvents.giftInfluencerRejected(gift.item, brand, influencer);
                }

                if (status === 'shortlist') {
                    analyticsEvents.giftInfluencerShortlisted(gift.item, brand, influencer);
                }

                if (status === 'complete') {
                    analyticsEvents.giftInfluencerCompleted(gift.item, brand, influencer);
                    if (!view?.review) {
                        setModalState((prev: ModalStateType) => ({
                            ...prev,
                            influencer: selectedWave?.influencer,
                            show: true
                        }))    
                    }
                }

                if (selectedWave?.uid === waveId && !smallDevice && status !== 'complete') {
                    setSelectedWave(nextWaveInList as Wave);
                }

                if (smallDevice) {
                    toast.success(`${status}${status === 'complete' ? 'd' : 'ed'}!`, {
                        duration: 2000,
                        style: {
                            textTransform: 'capitalize',
                            background: '#fff',
                            color: '#262161',
                        }
                    });
                }

                dispatcher(Actions.Gift.getGift(giftId, false)).catch((error: any) => {throw error});
            }
        })).catch((error: any) => {throw error});
    }

    /**
     * Handles changing the social applications
     * @param platform
     */
    const handleAnalyticsChange = (platform: SocialPlatformType) => {
        const analytics = view
            ?.influencer
            ?.insights
            ?.[platform];

        if (analytics) {
            setAnalytics({ ...analytics, platform });
        }
    }

    const handleResendMessage = (waveId: string, bubbleId: string) => {
        dispatcher(Actions.Wave.resendMessage(giftId, waveId, bubbleId)).catch((error: any) => {throw error});
    }

    const handleSendMessage = (waveId: string, message: string | File, wave: WaveItem) => {
        dispatcher(Actions.Wave.sendMessage(giftId, waveId, message)).catch((error: any) => {throw error});
    }

    const handleSubmitReview = (form: ReviewForm) => {
        setModalState((prev) => ({ ...prev, loading: true }));

        if (selectedWave && !modalState.completed) {
            dispatcher(Actions.Wave.submitReview(giftId, selectedWave.influencer?.uid, form, () => {
                setModalState((prev) => ({
                    ...prev,
                    completed: true,
                    loading: false
                }));

                if (gift.item && brand && modalState.influencer) {
                    analyticsEvents.giftInfluencerReviewed(
                        gift.item,
                        brand,
                        modalState.influencer,
                        form
                    );
                }
                dispatcher(Actions.Wave.getWaveItem(giftId, selectedWave.uid, true, false)).catch((error: any) => {throw error});

            })).catch((error: any) => {throw error});

        } else {
            setModalState((prev) => ({
                ...prev,
                influencer: undefined,
                completed: false,
                show: false,
                loading: false
            }))
        }
    }

    const handleViewProfile = (influencer: Influencer) => {
        setViewProfile(true);

        ReactGA.event({
            category: "custom event",
            action: "view_influencer_profile",
            label: "Brand viewed influencer profile", // optional
        });        


        dispatcher(Actions.Wave.getInfluencerReviews(influencer.uid, () => {
            if (gift.item && brand) {
                analyticsEvents.giftInfluencerProfileOpened(gift.item, brand, influencer);
            }
        })).catch((error: any) => {throw error});
    }

    const handleViewReviews = (influencer: Influencer) => {
        setViewReviews(true);
        dispatcher(Actions.Wave.getInfluencerReviews(influencer.uid, () => {
            if (gift.item && brand) {
                analyticsEvents.giftInfluencerProfileOpened(gift.item, brand, influencer);
            }
        })).catch((error: any) => {throw error});
    }

    const reviewEnabled = useMemo(() => {
        const reviewBubble = view?.bubbles.filter((bubble) => bubble.bubble_type === 'request-review' && bubble.sender_type === 'INFLUENCER')[0];
        console.log(view);
        const hasReview = view?.review;
        if (reviewBubble) {
            //@ts-ignore
            if (reviewBubble?.meta?.attributes?.has_review_received) {
                return true
            }
        }

        if (hasReview) {
            return true;
        }

        return false;
    }, [view])

    const closeModal = (modalName: string) => {
        if (brand?.uid) {
            API.Profile.setModalHistory(brand?.uid, modalName).then(() => {
                if (representative?.uid) {
                    dispatcher(Actions.Profile.getProfiles(representative?.uid, brand?.uid)
                ).catch((error: any) => {throw error})}
                
                setDismissModals(true);

            }).catch((error: any) => {
                errorReporter.report('Error dismissing modal', error);
            });
        }
    }

    return (
        <>
            <LeftContent id="scrollBox">
                <StyledTab
                    mobileStyle={{ width: '100%', marginLeft: '20px', marginRight: '20px' }}
                    items={tabItems}
                    selected={selectedTab}
                    // $loading={loading || loadingView}
                    onChange={handleTabChange}
                    unreadByTab={unreadByTab}
                >
                    <FiltersBar>
                        <FilterButtons
                            changeFilterItems={(val: string) => setSelectedFilter(val)}
                        />

                        {selectedSort && options && (
                            <FilterSelect
                                changeFilterItems={setSelectedSort}
                                selected={selectedSort}
                                options={options}
                            />
                        )}

                    </FiltersBar>

                    <StyledInput
                        type={'search'}
                        name={'search'}
                        label={''}
                        placeholder={`Search ${selectedTab.name.toLowerCase()}${selectedTab.name === 'Shortlist' ? 'ed' : ''} creators ...`}
                        value={search}
                        onChange={(_, value) => setSearch(value)}
                    >
                        <SearchIcon />
                    </StyledInput>

                    {children}

                    {!loading && gift.item && !isSearching && (
                        <EmptyState gift={gift.item} selectedTab={selectedTab} paramsStatus={paramsStatus} showWaveList={showWaveList} waves={list}/>
                    )}

                    {!loading && gift.item && list.length === 0 && isSearching && (
                        <NoResult image={'search'} title={`No applications found for "${search}"`} />
                    )}

                </StyledTab>

                {(showWaveList && (loading || (list.length > 0) || (selectedTab.id === 'new' && gift?.item?.waves_by_status.PENDING))) && (
                    <ScrollContainer ref={scrollContainer}>
                        <WaveList
                            items={list}
                            badgeCounts={badgeCounts}
                            fetchMoreWaves={fetchMoreWaves}
                            selectedTabId={selectedTab.id}
                            selectedId={selectedWave?.uid}
                            currentPage={currentPage}
                            lastPage={listPagination?.last_page}
                            hasMore={currentPage !== listPagination?.last_page}
                            loading={loading}
                            loadingViewMore={loadingViewMore}
                            searching={isSearching}
                            onClick={(wave) => setSelectedWave(wave)}
                            onStatusUpdate={waveStatusUpdated}
                            onViewMore={() => getMoreWaves(listPagination.current_page + 1)}
                        />
                    </ScrollContainer>
                )}

            </LeftContent>

            {smallDevice && selectedWave && (
                <ChatOverlay></ChatOverlay>
            )}
            <RightContent className={`${smallDevice && selectedWave ? 'mobile-chat' : ''} ${smallDevice && !selectedWave ? 'hide-chat' : ''}`}>
                {(view || loadingView) ? (
                    <>

                        {smallDevice && (
                            <>
                            <Toaster />
                            <SmallDeviceActions>
                                <div>
                                    <Button size='small' theme='aqua' className='back-btn' onClick={() => unselectWaveItem()}>
                                        <AngleLeftIcon />
                                    </Button>
                                </div>

                                <div>
                                    {selectedWave?.status !== 'REJECTED' && selectedWave?.status !== 'COMPLETED' && (
                                        <Button
                                            className={`reject-button smallBtn`}
                                            theme={'outline'}
                                            size={'small'}
                                            iconOnly={true}
                                            tooltip={!smallDevice ? 'Decline' : ''}
                                            onClick={() => waveStatusUpdated(selectedWave?.uid as string, 'reject', selectedWave?.influencer as Influencer)}
                                        >
                                            <CloseIcon />
                                        </Button>
                                    )}

                                    {(selectedWave?.status !== 'SHORTLISTED' && selectedWave?.status !== 'ACCEPTED' && !selectedWave?.influencer_withdrawn_at && selectedWave?.status !== 'COMPLETED') && (
                                        <Button
                                            className={`shortlist-button smallBtn`}
                                            theme={'outline'}
                                            size={'small'}
                                            iconOnly={true}
                                            tooltip={!smallDevice ? 'Shortlist' : ''}
                                            onClick={() => waveStatusUpdated(selectedWave?.uid as string, 'shortlist', selectedWave?.influencer as Influencer)}
                                        >
                                            <PlusIcon />
                                        </Button>
                                    )}

                                    {(selectedWave?.status !== 'ACCEPTED' && selectedWave?.status !== 'COMPLETED' && !loading) && (
                                        <Button
                                            className={`select-button`}
                                            size={'small'}
                                            iconOnly={false}
                                            disabled={!!selectedWave?.influencer_withdrawn_at}
                                            onClick={() => waveStatusUpdated(selectedWave?.uid as string, 'accept', selectedWave?.influencer as Influencer)}
                                        >
                                            {smallDevice ? (
                                                !selectedWave?.influencer_withdrawn_at ? <AcceptIcon /> : 'Withdrawn'
                                            ) : (
                                                <>
                                                    {!selectedWave?.influencer_withdrawn_at && <AcceptIcon />}
                                                    {selectedWave?.influencer_withdrawn_at ? 'Withdrawn' : 'Accept'}
                                                </>
                                            )}
                                        </Button>
                                    )}

                                    {(selectedWave?.status === 'ACCEPTED' || selectedWave?.status === 'COMPLETED') && (
                                        <Button
                                            className={`select-button`}
                                            size={'small'}
                                            iconOnly={false}
                                            disabled={selectedWave?.status === 'COMPLETED'}
                                            onClick={() => waveStatusUpdated(selectedWave?.uid as string, 'complete', selectedWave?.influencer as Influencer)}
                                        >
                                            {smallDevice ? (
                                                <StyledCheckIcon />
                                            ) : (
                                                <>
                                                    {selectedWave?.status !== 'COMPLETED' && <StyledCheckIcon />}
                                                    {selectedWave?.status === 'COMPLETED' ? 'Completed' : 'Complete'}
                                                </>
                                            )}
                                        </Button>
                                    )}

                                </div>
                            </SmallDeviceActions>
                            </>
                        )}
                        <InfluencerInfo
                            data={view?.influencer as Influencer}
                            influencerInsight={{
                                followers: analytics?.followers,
                                engagement_rate: analytics?.engagement_rate,
                                rating: analytics?.rating
                            }}
                            showAnalytics={true}
                            analyticsPlatform={analytics?.platform}
                            showReview={!!['ACCEPTED', 'COMPLETED'].find((value) => view?.status === value)}
                            reviewEnabled={!reviewEnabled}
                            loadingView={loadingView}
                            onViewProfile={handleViewProfile}
                            onViewReviews={handleViewReviews}
                            onSubmitReview={() => setModalState((prev: ModalStateType) => ({
                                ...prev,
                                influencer: selectedWave?.influencer,
                                show: true
                            }))}
                            onAnalyticsChange={handleAnalyticsChange}
                        />

                        <InfluencerChat
                            data={view}
                            onStatusUpdate={waveStatusUpdated}
                            brandName={brand?.name}
                            loadingView={loadingView}
                            onSendAttachment={handleSendMessage}
                            onSendMessage={handleSendMessage}
                            onSubmitReview={() => {
                                    setModalState((prev) => ({
                                        ...prev,
                                        influencer: selectedWave?.influencer,
                                        show: true
                                    })
                                )}
                            }
                            onResendMessage={handleResendMessage}
                            onViewProfile={handleViewProfile}
                            influencer={view?.influencer as Influencer}
                        />

                        <InfluencerCard
                            // TODO: maybe a better way to this
                            // without using optional chaning?
                            influencer={view?.influencer as Influencer}
                            show={viewProfile}
                            loadingReviews={loadingReviews}
                            onClose={() => setViewProfile(false)}
                        />

                        <InfluencerCard
                            // TODO: maybe a better way to this
                            // without using optional chaning?
                            influencer={view?.influencer as Influencer}
                            reviews={view?.review && [view.review] || []}
                            show={viewReviews}
                            loadingReviews={loadingReviews}
                            onClose={() => setViewReviews(false)}
                        />

                    </>
                ) : (
                    <EmptyPlaceholder image={'girl-1'} />
                )}
            </RightContent>



            {/* {brand && showWaveList && brand.modal_histories && !brand?.modal_histories?.['6893_info_modal'] && !dismissModals  ? (
                <ModalBtnLayout 
                    show={true}
                    onClose={() => closeModal('6893_info_modal')}
                    refresh={() => {}}
                />
            ) : null} */}




            <ReviewModal
                {...modalState}
                onClose={() => setModalState((prev) => ({
                    ...prev,
                    influencer: undefined,
                    completed: false,
                    show: false,
                }))}
                onSubmit={handleSubmitReview}
            />
            <GiftModal
                show={showPIIModal}
                type={'chat-message-leakage'}
                onClose={() => dispatcher(Actions.Wave.setShowPIIModal(false))}
                primaryAction={{
                    name: 'Dismiss',
                    action: () => dispatcher(Actions.Wave.setShowPIIModal(false))
                }}
            />
        </>
    );
}


const StyledTab = styled(Tab) <{
    $loading?: boolean;
}>`
    position: relative;
    padding-top: 20px;

    ${(props) => props.$loading && `
        pointer-events: none;
    `}

    @media (max-width:900px) and (min-width:0px) {
        flex: 0;
    }
`;

const LeftContent = styled.div`
    flex: 1;
    grid-area: left-content;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    height: 100%;
    overflow: hidden auto;
    position: relative;


    @media (max-width:900px) and (min-width:0px) {
        flex: 1;
        height: fit-content;
        overflow: unset;
    }

`;

const RightContent = styled.div`
    grid-area: right-content;
    display: flex;
    flex-direction: column;
    position: relative;
    border-left: 1px solid var(--grey-4);
    height: 100%;
    overflow-x: hidden;

    &.mobile-chat {
        position: fixed;
        z-index: 1030;
        background-color: white;
        height: 100%;
        width: 100vw;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 0px;
    }

    &.hide-chat {
        display: none;
    }
`;

const ScrollContainer = styled.div`
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 12px 0px;

    @media (max-width:900px) and (min-width:0px) {
        overflow: unset !important;
    }
`

const FiltersBar = styled.div`
    width: 100%;
    display: flex;
    padding: 10px 30px 0px 20px;
    justify-content: space-between;
    margin-top: 12px;
    // overflow-x: scroll;


    @media (max-width:900px) and (min-width:0px) {
        justify-content: space-between;
        padding: 10px 20px 0px 20px;

        div {
            margin: 0px;
        }
    }        
`

const SmallDeviceActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    background-color: white;
    padding: 12px;

    div {
        display: flex;

        button {
            margin-left: 8px;
            height: 44px;
       
            svg {
                width: 20px;
                height: 20px;
            }
    
        }

        .smallBtn {
            width: 44px;
        }    

        button:first-child {
            margin-left: 0px;
        }
    }
`

const ChatOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(38, 33, 97, 0.5);
    backdrop-filter: blur(8px);
    z-index: 1030;
`

const StyledInput = styled(FormInput)`
    flex: 1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 30px;
    right: 0;
    width: 285px;
    bottom: 0;
    top: 82px;
    height: 45px;

    div {
        background-color: var(--background-alt);
    }

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const StyledCheckIcon = styled(CheckIcon)`
  path {
    fill: #fff;
  }
`
