import {useEffect, useState, useMemo} from 'react';
import styled from 'styled-components';

import {
    Actions,
    useAppDispatch,
    useAppSelector
} from 'store';
import { Helpers } from 'utils';
import {
    Breadcrumbs,
    InfoCard,
    StatusLoader,
    SubscriptionModal,
    Tab,
    TabItemProps,
    Button,
    Table,
    TableColumn,
    Badge
} from 'app/components';
import { CancelModal } from 'app/components/CancelModal';
import { PrivateLayout } from 'app/layouts';
import { CheckoutForm, SubscriptionForm } from 'app/forms';
import { BillingInfo } from './BillingInfo';
import { SubscriptionInfo } from './SubscriptionInfo';
import { SubscriptionPageHeader } from './Header';
import errorReporter from 'services/errorReporter';
import { API } from 'api';
import { CardAEIcon, CardVisaIcon, CardMasterCardIcon, CardIcon } from 'app/icons';


const StyledBillingInfo = styled(BillingInfo)`
    width: 499px;
    margin: 0 auto;
`;

const StyledSubscriptionForm = styled(SubscriptionForm)`
    width: 100%;
    padding: 0 12px;
`;

const StyledCheckoutForm = styled(CheckoutForm)`
    padding: 0 86px;
`

const StyledTab = styled(Tab)`
    width: 100%;
    margin: 0 auto;
`;

const Container = styled.div`
    
    margin-top: 24px;
`;

const tabItems = [
    { id: 'subscription', name: 'Subscription' },
    // { id: 'billing-info', name: 'Tax invoices' }
];

export function SubscriptionBillingPage() {

    const dispatcher = useAppDispatch();

    const {
        global: { subscriptions },
        profile: {
            brand,
            status,
            loadingSubscription
        }
    } = useAppSelector((state) => state);

    const [selectedTab, setSelectedTab] = useState<TabItemProps>(tabItems[0]);

    const [modalState, setModalState] = useState<Dynamic>({ show: false });

    const [step, setStep] = useState<number>(0);
    
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showPauseModal, setShowPauseModal] = useState(false)

    const {
        global: {
            settings
        },
        profile: {
            billing,
            invoices,
            loadingInvoices
        }
    } = useAppSelector((state) => state);

    useEffect(() => {
        brand?.uid && dispatcher(Actions.Profile.getBrandProfile(brand?.uid)).catch((error: any) => {throw error});
        dispatcher(Actions.Profile.getBrandProfileBilling()).catch((error: any) => {throw error})
    }, [dispatcher, brand?.uid]);

    useEffect(() => {
        setStep(0);
    }, [selectedTab]);

    useEffect(() => {
        dispatcher(Actions.Profile.getInvoices()).catch((error: any) => {throw error});
    }, [dispatcher])

    const displayStatus = useMemo(() => {
        switch (brand?.subscriptionv2?.status) {
            case 'active':
                if (brand?.subscriptionv2.canceled_at) {
                    return `canceled`;
                } else {
                    return 'active';
                }
            case 'incomplete_expired':
                return 'expired';
            case 'past_due':
            case 'unpaid':
                return 'last payment unsuccessful';
            case 'trialing':
                if (brand?.subscriptionv2.canceled_at) {
                    return `canceled`
                } else {
                    return 'trial';
                }
            case 'incomplete':
                return 'Not subscribed';
            default:
                return brand?.subscriptionv2?.status;
        }
    }, [brand]);

    const handleCancelSubscription = (form: any) => {
        if (brand) {

            dispatcher(Actions.Profile.cancelBrandSubscription(() => {
                API.Shared.sendCancellation(brand.uid, form).catch((err: any) => {
                    errorReporter.report('Failed send cancellation', err)
                })

                if (settings) {
                    brand?.uid && dispatcher(Actions.Profile.getBrandProfile(brand?.uid)).catch((error: any) => {throw error});
                }
            })).catch((error: any) => {throw error});

        }
    }

    const handleShowSubscriptionModal = (
        type: string,
        code?: string
    ) => {
        const subscription = subscriptions.find((subscription) =>
            subscription.code === code);

        setModalState({
            type: type,
            subscriptionName: subscription?.name,
            show: 'true'
        });
    }

    const handleCloseSubscriptionModal = () => {
        setModalState({ show: false });
        setStep(0);
    }

    const handleChangeSubscriptionCode = (form: SubscriptionForm) => {
        if (Helpers.isNullOrUndefined(brand?.subscription?.subscription.code)) {
            dispatcher(Actions.Profile.createBrandProfileSubscription(form, () => {
                setStep(2);
            }));
        } else {
            dispatcher(Actions.Profile.setBrandProfileSubscription(form, () => {
                handleShowSubscriptionModal('card-updated', form.code);
            })).catch((error: any) => {throw error});
        }
    }

    const toggleCancelModal = () => {
        const newState = !showCancelModal
        setShowCancelModal(newState)
        if (!newState && brand?.uid) {
            dispatcher(Actions.Profile.getBrandProfile(brand?.uid)).catch((error: any) => {throw error})
        }
    }

    const togglePauseModal = () => {
        const newState = !showPauseModal
        setShowPauseModal(newState)
        if (!newState && brand?.uid) {
            dispatcher(Actions.Profile.getBrandProfile(brand?.uid)).catch((error: any) => {throw error})
        }
    }

    const pauseSubscription = () => {
        if (brand?.subscription) {
            dispatcher(Actions.Profile.pauseSubscription(brand?.subscription)).catch((error: any) => {throw error})
            setShowPauseModal(false);
        }
    }

    const hasSubscription = useMemo(() => {
        return loadingSubscription ?
            true : !Helpers.isNullOrUndefined(brand?.subscription?.subscribed_at)
    }, [brand])

    const hasActiveSubscription = useMemo(() => {
        return Helpers.isNullOrUndefined(brand?.subscription?.canceled_at);
    }, [brand])

    const resumeSubscription = () => {
        if (brand?.subscription) {
            dispatcher(Actions.Profile.resumeSubscription(brand?.subscription)).catch((error: any) => {throw error})
        }
    }

    const renderSubscriptionTab = () => {
        const hasSubscription = loadingSubscription ?
            true : !Helpers.isNullOrUndefined(brand?.subscription?.subscribed_at);
        const hasActiveSubscription =
            Helpers.isNullOrUndefined(brand?.subscription?.canceled_at);

        switch (step) {
            case 0:
                if (brand?.subscription?.subscription) {
                    return (
                        <SubscriptionInfo
                            brand={brand}
                            data={brand?.subscriptionv2 as BrandSubscription}
                            subscription={brand?.subscription?.subscription}
                            hasSubscription={hasSubscription}
                            hasActiveSubscription={hasActiveSubscription}
                            loadingSubscription={loadingSubscription}
                            onChangePlan={() =>  dispatcher(Actions.App.setShowPaymentModal(true, true))}
                            onChangeCard={() => setStep(2)}
                            onCancelPlan={(form: any) => handleCancelSubscription(form)}
                            subscriptions={subscriptions}
                        >
                            <SubscriptionPageHeader
                                title={'Current Plan'}
                            />
                        </SubscriptionInfo>
                    );
                } else {
                    return null
                }
            case 1:
                return (
                    <StyledSubscriptionForm
                        data={{
                            code: brand?.subscription?.subscription.code || ''
                        }}
                        loading={loadingSubscription}
                        onCancel={() => setStep(0)}
                        onSubmit={handleChangeSubscriptionCode}
                    >
                        <SubscriptionPageHeader
                            title={'Select your plan'}
                            center={true}
                        />
                    </StyledSubscriptionForm>
                );

            case 2:
                return (
                    <StyledCheckoutForm
                        brand={brand as BrandProfile}
                        code={brand?.subscription?.subscription.code || ''}
                        shownInModal={false}
                        showLoader={true}
                        interval={brand?.subscription?.subscription.frequency || ''}
                        plan={brand?.subscription?.subscription.name || ''}
                        onSuccess={() =>
                            handleShowSubscriptionModal('card-updated', brand?.subscription?.subscription.code)}
                        onCancel={() => {
                            setStep(0);
                        }}
                    >
                        <SubscriptionPageHeader
                            title={'Enter your billing info'}
                            center={true}
                        />
                    </StyledCheckoutForm>
                );

            default: return null;
        }
    }

    const renderSelectedTab = () => {
        switch (selectedTab.id) {
            case 'subscription':
                return renderSubscriptionTab();

            case 'billing-info':
                return (
                    <StyledBillingInfo
                    >
                        <SubscriptionPageHeader
                            title={'Tax invoices'}
                        />
                    </StyledBillingInfo>
                );

            default: return null;
        }
    }

    const cardImage = () => {
        if (!billing) {return}
        if (billing.card_brand === 'visa') {
            return <CardVisaIcon />
        } else if (billing.card_brand === 'mastercard') {
            return <CardMasterCardIcon />
        } else if (billing.card_brand === 'american_express') {
            return <CardAEIcon />
        } else {
            return <CardIcon />
        }
    }

    const getSubscriptionPlanName = (planName: string | undefined) => {
        if(planName === undefined) {
            return '';
        }
        if(planName === 'Gifted Starter'){
            return 'Gifted Basic';
        }
        return planName;
    }

    return (
        <PrivateLayout>
            <Container data-testid={'brandprofile-page'}>
                <Breadcrumbs
                    items={[
                        { id: 'dashboard', name: 'Dashboard', route: '/' },
                        { id: 'brandProfile', name: 'Subscription & Billing' }
                    ]}
                />

                <StatusContainer>
                    <StatusContainerInner>
                        <h2 style={{fontWeight: 600, fontSize: '16px'}}>Subscription Status:</h2>

                        <StatusBadge $status={brand?.subscriptionv2?.status} className={`${displayStatus === 'canceled' ? 'canceled' : ''}`}>
                                        {displayStatus === 'canceled' ? `Canceled: Active until ${Helpers.formatDate(brand?.subscriptionv2?.current_period_end || '', 'MMMM dd, Y')}` : displayStatus}{brand?.subscriptionv2?.paused_at ? ` until ${Helpers.formatDate(brand.subscriptionv2?.current_period_end || '', 'MMMM dd, Y')}` : null}
                        </StatusBadge>

                                    {brand?.subscriptionv2?.paused_at ? `After ${Helpers.formatDate(brand?.subscriptionv2?.current_period_end || '', 'MMMM dd, Y')} your subscription will be paused for one month.` : null}


                    </StatusContainerInner>
                </StatusContainer>
                
                <BillingTopContainer>

                    <BillingBox>
                        <BillingBoxHead>
                            <div>
                                <h2>Current Plan Summary</h2>
                            </div>
                            <div>
                                {hasSubscription && hasActiveSubscription && (
                                    <>
                                        <button onClick={() => toggleCancelModal()} className='danger'>
                                            Cancel Plan
                                        </button>
                                        {/* <button onClick={() => brand?.subscriptionv2?.paused_at ? resumeSubscription() : togglePauseModal()} className='secondary'>{brand?.subscriptionv2?.paused_at ? 'Resume plan' : 'Pause Plan'}</button> */}
                                        <button onClick={() =>  dispatcher(Actions.App.setShowPaymentModal(true, true))} className='primary'>{brand?.subscriptionv2?.status === 'trialing' ? 'Finish Trial' : 'Change Plan'}</button>
                                    </>
                                )}

                                {!hasSubscription || !hasActiveSubscription && (
                                    <button onClick={() =>  dispatcher(Actions.App.setShowPaymentModal(true, true))} className='primary'>{
                                        brand?.subscriptionv2?.canceled_at || (brand?.subscriptionv2?.status === 'paused' || brand?.subscriptionv2?.status === "canceled" || brand?.subscriptionv2?.status === "incomplete") ? 'Resubscribe' : 'Change plan'
                                    }</button>
                                )}
                            </div>
                        </BillingBoxHead>

                        <BoxBody>
                            <Item>
                                <p>PLAN:</p>
                                <h3>{getSubscriptionPlanName(brand?.subscription?.subscription.name)}</h3>
                            </Item>

                            <Item>
                                <p>BILLING CYCLE:</p>
                                <h3>{brand?.subscription?.subscription.frequency}</h3>
                            </Item>

                            <Item>
                                <p>PLAN COST:</p>
                                <h3>${parseFloat(brand?.subscription?.subscription.amount?.toFixed(2) || '0.00').toLocaleString()}</h3>
                            </Item>


                        </BoxBody>

                        <BoxFooter>
                            <div>
                            {!loadingSubscription && brand?.subscriptionv2?.status !== "canceled" &&
                                        `Purchase date: ${Helpers.formatDate(brand?.subscriptionv2?.subscribed_at || brand?.subscriptionv2?.current_period_start || '', 'MMMM dd, Y')}`}
                            </div>
                            <div>
                            <span>
                                    {!loadingSubscription && !brand?.subscriptionv2?.paused_at && brand?.subscriptionv2?.status !== "canceled" &&
                                        `${brand?.subscriptionv2?.canceled_at ? 'Expiry':'Next billing'} date: ${Helpers.formatDate(brand?.subscriptionv2?.expires_at || brand?.subscriptionv2?.current_period_end || '', 'MMMM dd, Y')}`}

                                        {brand?.subscriptionv2?.paused_at && `Paused on ${Helpers.formatDate(brand?.subscriptionv2?.paused_at, 'MMMM dd, Y')}`}
                                </span>
                            </div>
                        </BoxFooter>
                    </BillingBox>


                    <BillingBox style={{marginRight: '0px'}}>
                        <BillingBoxHead>
                            <h2>Payment Method</h2>
                        </BillingBoxHead>

                        <BoxBody>
                            <PaymentInfo>
                                {cardImage()}
                                <CardInfo>
                                    <h3>{billing?.card_brand}</h3>
                                    <h4>Ending in {billing?.card_last_four}</h4>
                                    <p>Expiry on {billing?.expiry_month}/{billing?.expiry_year}</p>
                                </CardInfo>
                                <ChangeBtn onClick={() => setStep(2)}>Change</ChangeBtn>
                            </PaymentInfo>
                        </BoxBody>
                    </BillingBox>

                </BillingTopContainer>
                
                {step === 2 && (
                <InfoCard width={'1100px'}>
                <StyledTab
                    items={tabItems}
                    selected={selectedTab}
                    onChange={setSelectedTab}
                >
                    {renderSelectedTab()}
                </StyledTab>
            </InfoCard>
                )}

            <InvoicesContainerOuter>
                    <InvoicesContainerInner>
                    <h2 style={{fontWeight: 600, fontSize: '16px'}}>Invoices</h2>
                <Table
                    rows={invoices}
                    columns={[
                        {
                            id: 'invoice_date',
                            name: 'DATE',
                            renderer: (data, { key }) => {
                                return (
                                <TableColumn key={key}>
                                    {Helpers.formatDate(data, 'dd MMMM, Y')}
                                </TableColumn>
                                )
                            }
                        },
                        { id: 'type', name: 'TYPE' },
                        {
                            id: 'link',
                            name: 'RECEIPT',
                            renderer: (data, { key }) => (
                                <StyledTableColumn
                                    key={key}
                                    onClick={() => window.open(data as string)}
                                >
                                    Download PDF
                                </StyledTableColumn>
                            )
                        }
                    ]}
                    loading={loadingInvoices}
                />
                                    </InvoicesContainerInner>

            </InvoicesContainerOuter>

            </Container>

            <SubscriptionModal
                {...modalState}
                onClose={handleCloseSubscriptionModal}
                onClick={handleCloseSubscriptionModal}
            />

            <StatusLoader
                show={status !== 'idle'}
                status={status}
            />


            {showPauseModal && (
                <ModalBackdrop onClick={() => togglePauseModal()}>
                    <Modal onClick={(e) => e.stopPropagation()}>
                        <ModalContent>
                            <h1>Are you sure you want to Pause your plan for 1 month?</h1>
                        </ModalContent>

                        <ModalDesc>
                            <p>After {Helpers.formatDate(brand?.subscriptionv2?.current_period_end || '', 'MMMM dd, Y')} your subscription will be paused for one month, and will recommence on {' '}
                            {/* this looks confusing, but basically it just adds a month and formats it */}
                            {new Date(new Date(Helpers.formatDate(brand?.subscriptionv2?.current_period_end || '', 'MMMM dd, Y')).setMonth(new Date(Helpers.formatDate(brand?.subscriptionv2?.current_period_end || '', 'MMMM dd, Y')).getMonth() + 1)).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})}.</p>
                        </ModalDesc>

                        <ModalActions>
                            <ModalButton theme="outline" onClick={() => togglePauseModal()}>No</ModalButton>
                            <ModalButton onClick={() => {
                                pauseSubscription();
                            }}>Yes</ModalButton>
                        </ModalActions>
                    </Modal>
                </ModalBackdrop>

            )}

            {showCancelModal && (
                <CancelModal currentPlan={brand?.subscription?.subscription.name} onDowngrade={() => dispatcher(Actions.App.setShowPaymentModal(true, true))} onPause={() => togglePauseModal()} show={showCancelModal} brand={brand || undefined} onClose={() => toggleCancelModal()} onSubmit={(form: any) =>  handleCancelSubscription(form)} endDate={Helpers.formatDate(brand?.subscriptionv2?.current_period_end || '', 'MMMM dd, Y')} subtext={`You will continue to be able to use Gifted until ${Helpers.formatDate(brand?.subscriptionv2?.current_period_end || '', 'MMMM dd, Y')} when your current plan expires.`} />
            )}


        </PrivateLayout>
    );
}

const StatusBadge = styled(Badge) <{
    $status?: SubscriptionStatus;
}>`
    align-items: center;
    align-self: center;
    font-size: 11px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
    color: var(--grey-9);
    margin-left: 16px;
    margin-right: 16px;
    cursor: default;

    ${(props) => (props.$status === 'active' || props.$status === 'trialing') && `
        background-color: var(--success-muted);
        color: var(--success-active);
    `}
    
    ${(props) => (props.$status === 'canceled') && `
        background-color: var(--warning-muted);
        color: var(--warning-active);
    `}
    
    ${(props) => (props.$status === 'unpaid' || props.$status === 'past_due' || props.$status === 'incomplete' || props.$status === 'incomplete_expired') && `
        background-color: var(--error-muted);
        color: var(--error-active);
    `}

    &.canceled {
        background-color: var(--warning-muted);
        color: var(--warning-active);
    }

`;

const StatusContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const StatusContainerInner = styled.div`
    width: 1111px;
    display: flex;
    align-items: center;
    height: 80px;
`

const InvoicesContainerOuter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
`

const InvoicesContainerInner = styled.div`
    width: 1111px;
`

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    background-color: rgba(38,33,97,0.5);
    backdrop-filter: blur(8px);
`
const Modal = styled.div`
    width: 537px;
    background-color: var(--background-alt);
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
`
const ModalContent = styled.div`
    font-weight: bold;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin: 8px 0;
    letter-spacing: -0.75px;
    color: var(--grey-9);
    h1 {
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: -1.25px;
        color: #262161;
        font-weight: 400;
    }
`
const ModalDesc = styled.div`
    margin-top: 24px;
    p {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.25px;
        color: #9299BE;
        font-weight: 400;
    }
`
const ModalActions = styled.div`
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
`
const ModalButton = styled(Button)`
    width: 47%;
`

const BillingTopContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;

    @media (max-width:900px) and (min-width:0px) {
        flex-direction: column;
    }
`

const BillingBox = styled.div`
    background: white;
    width: 540px;
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    border-radius:6px;
    box-shadow: var(--shadow-1);

    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
        margin-bottom: 24px;
    }
`

const BillingBoxHead = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: solid 2px #ECECEC;
    padding: 8px 16px;
    align-items: center;

    h2 {
        font-size: 16px;
        font-weight: 500;
    }

    button {
        font-size: 12px;
        margin-right: 6px;
        padding: 4px 8px;
        border-radius: 6px;
        border: none;
        background: white;
        cursor: pointer;
        font-weight: 600;
        transition: all 300ms ease;

        &.primary {
            background: var(--blue);
            color: white;

            &:hover {
                background-color: rgba(38, 33, 97, 0.35);
                color: var(--blue);            
            }
        }

        &.secondary {
            border: solid 1.5px var(--blue);
            color: var(--blue);

            &:hover {
                background-color: rgba(84, 90, 119, 0.15);
                color: var(--grey-7);
            }
        }

        &.danger {
            border: solid 1.5px #FF778C;
            color: #FF778C;

            &:hover {
                background-color: rgba(255, 100, 124, 0.15);
                color: var(--error-active);
            }
        }
    }

    @media (max-width:900px) and (min-width:0px) {

        button {
            font-size: 9px;
            padding: 4px 8px;
        }
    }
`

const BoxBody = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 32px 16px;
`

const Item = styled.div`
    display: flex;
    flex-direction: column;

    p {
        color: #696969;
        margin: 0px;
        padding: 0px;
        font-size: 12px;
    }

    h3 {
        margin: 0px;
        padding: 0px;
        font-size: 16px;
        font-weight: 600;
        color: var(--blue);
    }
`

const BoxFooter = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 32px;
    padding-right: 16px;
    padding-left: 16px; 

    div {
        font-size: 12px;
    }
`

const PaymentInfo = styled.div`
    border: solid 2px #ECECEC;
    border-radius: 6px;
    display: flex;
    position: relative;
    width: 100%;
    padding: 12px 8px;
`

const CardInfo = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        margin: 0px;
        padding: 0px;
        font-weight: 600;
        font-size: 16px;

    }

    h4 {
        margin: 0px;
        padding: 0px;
        font-weight: 500;
        color: #696969;
    }

    p {
        margin: 0px;
        padding: 0px;
        color: #696969;
    }
`

const ChangeBtn = styled.button`
    position: absolute;
    right: 8px;
    font-size: 12px;
    margin-right: 6px;
    padding: 4px 8px;
    border-radius: 6px;
    border: none;
    background: white;
    cursor: pointer;
    font-weight: 600;
    transition: all 300ms ease;
    border: solid 1.5px var(--blue);
    color: var(--blue);

    &:hover {
        background-color: rgba(84, 90, 119, 0.15);
        color: var(--grey-7);
    }
`
const StyledTableColumn = styled(TableColumn)`
    text-decoration: underline;
    text-underline-offset: 2px;
    color: var(--info-active);
    transition: color 300ms ease;
    cursor: pointer;

    &:hover {
        color: var(--aqua);
    }
`;